import {directive as onClickaway} from 'vue-clickaway';

export default {
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    type: {
      type: String,
      default: 'objectData'
    },
    value: {},
    label: {
      type: String,
      default: ''
    },
    progress: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    itemValue: {
      type: String,
      default: 'default'
    },
    itemTitle: {
      type: String,
      default: 'name'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      openSelect: false,
      selectedItem: this.value,
    }
  },
  watch: {
    value() {
      this.changeSelect(this.value)
    }
  },
  created() {
    this.selectedItem = this.value;
  },
  methods: {
    close() {
      this.openSelect = !this.openSelect
    },
    changeSelect(item) {
      this.openSelect = false;
      this.selectedItem = item;
      this.$emit('input', item);
      setTimeout(() => {
        this.openSelect = false;
      }, 200)
    },
    away: function () {
      this.openSelect = false
    },
  }
}
