import mainSelect from '../../../../components/main-select/index.vue'
import {mapActions, mapMutations} from "vuex";
import categoryFilter from '../category-filter/index.vue'

export default {
  name: "select-sort",
  components: {
    mainSelect, categoryFilter
  },
  data() {
    return {
      catalogToggler: false,
      selectedKey: {
        id: 0,
        title: 'По замовчуванню',
        key: ''
      },
      keys: [
        {
          id: 0,
          title: 'По замовчуванню',
          key: ''
        },
        {
          id: 1,
          title: 'По Ціні ↓',
          key: 'priceDown'
        },
        {
          id: 2,
          title: 'По Ціні ↑',
          key: 'priceUp'
        },
        {
          id: 3,
          title: 'По алфавіту ↓',
          key: 'alphabeticalDown'
        },
        {
          id: 4,
          title: 'По алфавіту ↑',
          key: 'alphabeticalUp'
        },
      ]
    }
  },
  created() {
    this.setCategoryType(this.keys[0]);
  },
  methods:{
    ...mapActions({
      fetchList: 'category/GET_FILTER_PRODUCTS_LIST'
    }),
    ...mapMutations({
      setCategoryType: `category/SET_PRODUCTS_SORT_TYPE`,
    }),
    selectItem(type){
      if(type === 'up'){
        if(this.selectedKey.key === 'priceDown'){
          this.selectedKey = this.keys[2]
          this.setCategoryType(this.selectedKey);
          this.fetchList({ slug: this.$route.params.slug, data: this.$route.query });
        }else if(this.selectedKey.key === 'alphabeticalDown'){
          this.selectedKey = this.keys[4]
          this.setCategoryType(this.selectedKey);
          this.fetchList({ slug: this.$route.params.slug, data: this.$route.query });
        }
      }else if (type === 'down'){
        if(this.selectedKey.key === 'priceUp'){
          this.selectedKey = this.keys[1]
          this.setCategoryType(this.selectedKey);
          this.fetchList({ slug: this.$route.params.slug, data: this.$route.query });
        }else if(this.selectedKey.key === 'alphabeticalUp'){
          this.selectedKey = this.keys[3]
          this.setCategoryType(this.selectedKey);
          this.fetchList({ slug: this.$route.params.slug, data: this.$route.query });
        }
      }else{
        this.setCategoryType(this.selectedKey);
        this.fetchList({ slug: this.$route.params.slug, data: this.$route.query });
      }
    }
  }
}
