import {VueSlideToggle} from "vue-slide-toggle";
import categorySelect from '../select/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "category-filter",
  data() {
    return {
      open: true,
    }
  },
  computed: {
    ...mapGetters({
      attributesList: 'category/filters'
    })
  },
  watch: {
    '$route'() {
      this.resetSkip()
      this.updateRouter();
    }
  },
  created() {
    this.resetSkip()
    this.fetchAllAttributes({slug: 'all'}).then(() => {
      this.updateRouter();
    })
  },
  components: {
    VueSlideToggle,
    categorySelect
  },
  methods: {
    ...mapActions({
      fetchAllAttributes: 'category/GET_PRODUCTS_LIST',
      fetchList: 'category/GET_FILTER_PRODUCTS_LIST',
      // fetchCategoryList: `category/GET_PRODUCTS_LIST`,
    }),
    ...mapMutations({
      resetFilters: 'category/RESET_FILTERS',
      resetSkip: 'category/RESET_CATEGORY_SKIP'
    }),
    resetFilter() {
      this.resetSkip()
      this.$router.push({
        name: 'catalog',
        params: {slug: this.$route.params.slug}
      });
      this.resetFilters(true)
      // console.log(this.$refs);
      this.fetchList({slug: this.$route.params.slug, data: this.$route.query});
    },
    updateRouter() {
      this.resetSkip()
      const query = this.$route.query;
      let currentCategories = [];
      this.attributesList.forEach(e => {
        if (query.hasOwnProperty(e.key)) {
          currentCategories.push(e);
        }
      });
      let arr = [];
      currentCategories.forEach(e => {
        e.value.forEach(t => {
          for (let i in query) {

            let currentQuery = query[i];
            if (!Array.isArray(currentQuery)) {
              currentQuery = [];
              currentQuery.push(query[i]);
            }
            if (currentQuery) {
              currentQuery.forEach(c => {
                if (t.key === c) {
                  const elem = _.find(arr, ['key', e.key]);
                  if (elem) {
                    elem.categories.push(t);
                  } else {
                    arr.push({
                      name: e.name,
                      key: e.key,
                      categories: [t]
                    });
                  }
                }
              });
            }

          }
        });
      });
      // this.selectedFilters = arr;
    },
    changeSlideToggle(val) {
      this.open = val
    }
  }
}
