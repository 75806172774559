import _ from "lodash";
import {VueSlideToggle} from "vue-slide-toggle";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    props: {
        category: {
            type: Object,
            default: () => {
            }
        },
        slug: {
            type: '',
            default: () => ''
        },
        selected: '',
        default: () => ''
    },
    components: {
        VueSlideToggle
    },
    name: "recursive-input",
    data() {
        return {
            id: [],
            test: [],
            checked: [],
            selectedFilters: [],
            selectedInputs: [],
            open: true
        }
    },
    watch: {
        '$route'() {


            Object.values(this.$route.query).forEach(el => {
                if (Array.isArray(el)) {
                    el.forEach(elem => {
                        if (!this.selectedInputs.includes(parseInt(elem))) {
                            this.selectedInputs.push(parseInt(elem))
                        }

                    })

                } else {
                    if (!this.selectedInputs.includes(parseInt(el))) {
                        this.selectedInputs.push(parseInt(el))
                    }

                }
            })
            // console.log(this.category.id, 'inputs');
        }
    },
    computed: {
        ...mapGetters({
            attributesList: 'category/filters',
            resetFilters: 'category/resetFilters'
        }),
        query() {
            let category = this.selectedInputs.reduce(el => {

                return el.id === this.category.id
            })
            return category

        },
        selectedInput() {
            return this.selectedInputs.filter(el => {
                return (el === this.category.id)
            })
        },
    },
    created() {

        Object.entries(this.$route.query).forEach( itemEntries => {
           if (itemEntries[0] === this.slug) {
               let values = itemEntries[1] ;
               values = Array.isArray(values) ? values : [values]  ;
               values.forEach(value => {
                   if (Number(value) === this.category.id) {
                       this.checked = [this.category.slug];
                   }
               })


           }
        });

        Object.values(this.$route.query).forEach(el => {
            if (Array.isArray(el)) {
                el.forEach(elem => {
                    this.selectedInputs.push(parseInt(elem))
                    // this.$emit('input', {id: this.category, type: 'add'})
                })

            } else {
                this.selectedInputs.push(parseInt(el))
            }
        })

    },
    methods: {
        ...mapActions({
            fetchList: 'category/GET_FILTER_PRODUCTS_LIST'
        }),
        ...mapMutations({
            changeResetFilters: 'category/RESET_FILTERS'
        }),
        resetInput() {
            this.checked = []
            this.selectedFilters = []
        },
        findId() {

        },
        currentQuery(query) {
            return this.selectedInputs.forEach(el => {
                if (query === el) {

                    return '1'
                } else {

                    return '2'
                }
            })
        },
        selectFilter(category) {


            if (this.checked.length) {
                this.$emit('input', {id: category, type: 'add'})
                // Object.values(this.$route.query).forEach((el, i) => {
                //     if (Array.isArray(el)) {
                //         el.forEach((elem, idx) => {
                //             if (!this.selectedInputs.includes(parseInt(elem))) {
                //
                //                 return this.selectedInputs.push(parseInt(elem))
                //             } else {
                //                 let idx = this.selectedInputs.findIndex(el => el === category)
                //                 console.log(idx, 'idx 11');
                //                 console.log(this.selectedInputs, 11);
                //             }
                //         })
                //     } else {
                //         if (!this.selectedInputs.includes(parseInt(el))) {
                //
                //             return this.selectedInputs.push(parseInt(el))
                //         } else {
                //             let idx = this.selectedInputs.findIndex(el => el === category)
                //             console.log(idx, 'idx 22');
                //             console.log(this.selectedInputs, 22);
                //         }
                //     }
                // })


            } else {
                this.$emit('input', {id: category, type: 'remove'})
                // let idx = this.selectedInputs.findIndex(el => el === category)
                // return this.selectedInputs.splice(idx, 1)
            }

            // console.log(this.selectedInputs);
        },
        selectFilter2(value) {
            this.$emit('input', {id: value.id, type: value.type})
        }
    }
}
