import _ from "lodash";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {VueSlideToggle} from "vue-slide-toggle";
import recursiveInput from '../recursive-input/index.vue'

export default {
    name: "category-select",
    components: {
        VueSlideToggle,
        recursiveInput
    },
    props: {
        category: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: () => 'label'
        },
        slug: {
            type: String,
            default: () => ''
        }
    },

    data() {
        return {
            open: true,
            selectedFilters: [],
            checked: [],
            selectedInputs: []
        }
    },
    watch: {
        // 'resetFilters'() {
        //   if(this.resetFilters === true){
        //     console.log('11',this.checked)
        //     this.checked = []
        //     this.selectFilters = []
        //
        //     // this.changeResetFilters(false)
        //   }
        // },
        '$route'() {
            const query = this.$route.query;
            // console.log('query',query)
            if (query[this.slug]) {

                let currentObj = _.find(this.attributesList, ['id', this.slug]);


                let currentQuery = query[this.slug];

                if (!Array.isArray(currentQuery)) {
                    currentQuery = [];
                    currentQuery.push(query[this.slug]);
                }

                if (currentObj) {
                    currentObj.forEach(e => {

                        currentQuery.forEach(t => {
                            if (e === t) {
                                selected.push(e);
                            }
                        });


                    });
                }


                // this.checked = selected;

            } else {
                this.checked = [];
            }
            if (this.$route.query === {}) {

                this.checked = []
                this.selectFilters = []
                // this.changeResetFilters(false)
            }
        }
    },
    computed: {
        ...mapGetters({
            attributesList: 'category/filters',
            resetFilters: 'category/resetFilters'
        }),
        query() {
            this.selectedFilters = [];
            if (this.checked) {
                this.checked.forEach(e => {
                    this.selectedFilters.push(e)

                });
            }
            // for (let key in this.$route.query) {
            //
            //     if (key === this.slug) {
            //         let newSelectedFilters = []
            //         this.$route.query[key].forEach((query, idx) => {
            //             this.selectedFilters.forEach(filter => {
            //                 if (query !== filter) {
            //                     newSelectedFilters.push(filter)
            //                 }
            //             })
            //         })
            //
            //         this.selectedFilters = newSelectedFilters
            //
            //     }
            //
            // }

            return {

                [this.slug]: this.selectedFilters
            }
        },

    },
    created() {
        // console.log(this.$route.query, 'route.query');
        // console.log(this.query, 'query')
        this.eventHub.$emit('checkedInputs', this.checked)
        if (this.attributesList) {
            const query = this.$route.query;
            if (query.hasOwnProperty(this.slug)) {
                this.open = true;
                let currentObj = _.find(this.attributesList, ['slug', this.slug]);

                let selected = [];
                let currentQuery = query[this.slug];

                if (!Array.isArray(currentQuery)) {
                    currentQuery = [];
                    currentQuery.push(query[this.slug]);
                }

                if (currentObj) {
                    currentObj.categories.forEach(e => {
                        currentQuery.forEach(t => {
                            if (e.id === Number(t)) {
                                selected.push(e.id);
                            }
                        });
                    });

                    this.checked = selected;
                }

            }
        }

        // if (this.$route.query.slug) {
        //   this.checked.slug = this.$route.query.slug
        //   this.$emit('show', true)
        // }
    },
    methods: {
        ...mapActions({
            fetchList: 'category/GET_FILTER_PRODUCTS_LIST'
        }),
        ...mapMutations({
            changeResetFilters: 'category/RESET_FILTERS'
        }),

        selectFilter(val) {


            if (val.type === 'add') {
                this.checked.push(val.id)
            } else {
                this.checked = _.without(this.checked, val.id)


            }
            // console.log(this.$route.query, 'route.query');
            // console.log(this.query, 'query');



            let newObj = Object.assign({}, this.$route.query, this.query)
            // console.log(newObj, 'newObj')
            this.$router.push({
                name: 'catalog',
                params: this.$route.params,
                query: newObj
            }).catch(() => {
            });
            this.sendRequest();
        },
        sendRequest: _.debounce(function () {
            this.fetchList({data: {filters: this.checked}, slug: this.$route.params.slug});
        }, 300),
    }
}
