import product from '../../components/product/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
import categoryFilter from './components/category-filter/index.vue'
import selectSort from './components/select-sort/index.vue'
import customBreadcrumbs from '../../components/custom-breadcrumbs/index.vue'

export default {
  name: "catalog",
  components: {
    product, categoryFilter, selectSort, customBreadcrumbs
  },
  data() {
    return {
      page: 1,
      breadcrumbsList: null,
    }
  },
  watch: {
    'list': function () {
      this.page = 1
    },
    '$route.params.slug'() {
      let obj = []
      for (let i in this.$route.query) {
        if (Array.isArray(this.$route.query[i])) {
          obj.push(this.$route.query[i])
        } else {
          // obj[i] = []
          obj.push(this.$route.query[i])
        }
      }
      this.changeCurrentCategory(this.$route.params.slug)
      if (this.$route.name !== 'catalog-search') {
        this.fetchList({slug: this.$route.params.slug, data: {filters: obj}}).then(() => {
          // console.log('listProducts', this.list)
          // this.breadcrumbsList = this.list.products[0].breadcrumbs
          // this.breadcrumbsList.pop()
        })
      } else {
        this.fetchSearchItems({key: this.$route.params.key, include: 'products'})
      }
      //     if (this.$route.params.slug === 'all') {
      //       this.fetchAllProducts()
      //       this.fetchAllAttributes({slug: 'all'})
      //     } else {
      //       this.fetchCategoryProducts(this.$route.params.slug)
      //     }
    }
  },
  mounted() {

    this.changeCurrentCategory(this.$route.params.slug)
    let obj = []
    for (let i in this.$route.query) {
      if (Array.isArray(this.$route.query[i])) {
        obj.push(this.$route.query[i])
      } else {
        // obj[i] = []
        obj.push(this.$route.query[i])
      }
    }
    console.log(obj, 'obj');
    if (this.$route.name !== 'catalog-search') {
      this.fetchList({
        slug: this.$route.params.slug,
        data: {filters: obj},
        skip: this.page,
        limit: this.page * 12
      }).then(() => {
        // console.log('listProducts', this.list)
        // this.breadcrumbsList = this.list.products[0].breadcrumbs
        // this.breadcrumbsList.pop()
      })
    } else {
      this.fetchSearchItems({key: this.$route.params.key, include: 'products'})
    }

  },
  computed: {
    ...mapGetters({
      list: 'category/list',
      listLoading: 'category/listLoading',
      isAuthenticated: 'auth/isAuthenticated',
      wishList: 'favorites/whichList',
      categorySkip: 'category/categorySkip',
      categoryLimit: 'category/categoryLimit'
    }),
    productList() {
      if (this.isAuthenticated) {
        if (this.list) {
          if (!this.wishList) return []
          return this.list.products.map(e => {
            const item = this.wishList.find(t => t.id === e.id)
            e.selected = !!item
            return e
          })
        }
      } else {
        return this.list.products
      }
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'category/GET_FILTER_PRODUCTS_LIST',
      fetchSearchItems: 'search/GET_SEARCH_LIST',
    }),
    ...mapMutations({
      changeCurrentCategory: 'card/CHANGE_CURRENT_CATEGORY',
      changeSkip: 'category/INCREMENT_SKIP'
    }),
    paginate() {
      this.changeSkip()
      let obj = []
      for (let i in this.$route.query) {
        if (Array.isArray(this.$route.query[i])) {
          obj.push(this.$route.query[i])
        } else {
          // obj[i] = []
          obj.push(this.$route.query[i])
        }
      }
      if (this.$route.name !== 'catalog-search') {
        this.fetchList({
          slug: this.$route.params.slug,
          data: {filters: obj},
          skip: this.categorySkip,
          limit: this.categoryLimit
        }).then(() => {
        })
      } else {
        this.fetchSearchItems({key: this.$route.params.key, include: 'products'})
      }
    }
  }
}
